.products-by-purpose {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #7bb6e4;
}

.products-by-purpose__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.products-by-purpose__title {
  width: 90%;
  font-size: 0.7em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: left;
  text-transform: uppercase;
  margin: 0 auto;
}

.red-text {
  display: inline-block;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 1.2em;
  color: #161615;
  text-transform: uppercase;
}

.products-by-purpose__list {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
}

.products-by-purpose__item {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: hidden;
}

.products-by-purpose__image {
  width: 90%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.products-by-purpose__content {
  width: 90%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.products-by-purpose__product-title {
  width: 100%;
  font-size: 0.7em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  margin: 0;
}
.products-by-purpose__product-taste {
  width: 100%;
  font-size: 0.7em;
  padding: 10px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.products-by-purpose__title-non {
  width: 90%;
  margin: 0 auto;
  font-size: 0.7em;
  padding: 10px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: center;
  text-transform: uppercase;
}
