.social-media {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7bb6e4;
}

.social-media__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.social-media__title {
  width: 100%;
  font-size: 0.9em;
  margin: 0 auto;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.social-media__subtitle {
  width: 90%;
  font-family: ElMessiri-Medium, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
}

.social-media__telegram-content {
  width: 90%;
  margin: 0 auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3px;
}

.social-media__telegram-content img:hover {
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
}

.social-media__telegram-content img {
  max-width: 150px;
  max-height: 150px;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 769px) {
  .social-media__wrapper {
    padding-bottom: 20px;
  }

  .social-media__telegram-content img {
    max-width: 100px;
    max-height: 100px;
  }
}
