.main-reviews {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-reviews__container {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.main-reviews__title {
  color: #203b3b;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 32px;
  line-height: 1.23;
  text-align: center;
  margin: 0 auto;
}

.main-reviews__wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-reviews__block {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  justify-items: center;
  align-items: start;
  gap: 10px;
}

.main-reviews__img img {
  width: 100%;
  margin: 0;
}

.main-reviews__arrow {
  width: 50px;
  height: 70px;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
}

.main-reviews__left-arrow {
  transform: rotate(-180deg);
}

.main-reviews__point-group {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.main-reviews__img-point {
  width: 8px;
  height: 8px;
  background-color: #378bc8;
  border-radius: 50%;
}

.main-reviews__img-point:hover {
  background-color: #811504;
}

.main-reviews__author {
  font-size: 1em;
  line-height: 1.35;
  font-family: ElMessiri-Bold, sans-serif;
  color: #000;
  margin: 10px auto;
}

.main-reviews__content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-reviews__content-subtitle {
  width: 90%;
  margin: 20px auto 0;
  font-size: 0.7em;
  line-height: 1.35;
  font-family: ElMessiri-Bold, sans-serif;
}

.main-reviews__marketplace-list {
  width: 60%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
  padding: 20px 0;
}

.main-reviews__marketplace-item {
  width: 100%;
  max-width: 80px;
  min-width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

@media screen and (max-width: 610px) {
  .main-reviews__block {
    grid-template-columns: 30px auto 30px;
  }
  .main-reviews__arrow {
    width: 30px;
    height: 50px;
  }
  .main-reviews__point-group {
    gap: 10px;
  }
  .main-reviews__container {
    padding: 20px 0;
  }
}
