.sakura-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

@keyframes fall {
  0% {
    transform: translateY(0) translateX(calc(100vw * var(--random-x)))
      rotate(0deg);
  }
  100% {
    transform: translateY(calc(100vh + 50px))
      translateX(calc(100vw * var(--random-x) - 50px)) rotate(360deg);
  }
}

.sakura-petal {
  position: absolute;
  left: calc(100vw * var(--random-x));
  width: 25px;
  height: 25px;
  opacity: 0.8;
  border-radius: 50%;
  animation: fall var(--random-duration) linear infinite;
  animation-delay: var(--random-delay);
}

.sakura-petal img {
  width: 100%;
  height: auto;
  display: block;
}

.sakura-petal-img {
  position: absolute;
  left: calc(100vw * var(--random-x));
  width: 35px;
  height: 35px;
  opacity: 0.8;
  border-radius: 50%;
  animation: fall var(--random-duration) ease infinite;
  animation-delay: var(--random-delay);
}

.sakura-petal-img img {
  width: 100%;
  height: auto;
  display: block;
}
