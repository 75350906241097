.dropdown {
  position: absolute;
  width: 100%;
  background-color: #7bb6e4;
  padding: 5px 20px 5px 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 7px;
  cursor: none;
  z-index: 10;
  min-width: fit-content;
}

.dropbtn {
  font-family: ElMessiri-Bold, sans-serif;
  color: rgb(18, 50, 61);
  padding: 5px 7px;
  font-size: 0.5em;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  border: 1px solid #5e5d5b;
  text-transform: uppercase;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
}
