.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.no-access__wrapper {
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.no-access__title {
  color: #000000;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.55;
  margin: 30px 30px 10px;
}

.no-access__subtitle {
  color: #000000;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.55;
  margin: 0;
}

.no-access__text {
  width: 90%;
  font-family: ElMessiri-Regular, sans-serif;
  color: #073042;
  font-size: 20px;
  line-height: 1.35;
  text-align: left;
  margin: 25px;
}

.no-access__link {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 8px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #92bbda;
  box-shadow: 0 0 10px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.no-access__link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.no-access__link:hover::before {
  height: 100%;
}

.no-access__link:hover {
  color: rgb(242, 247, 244);
}

@media screen and (max-width: 768px) {
  .no-access__text {
    font-size: 14px;
    text-align: center;
    margin: 15px;
  }

  .no-access__link {
    font-size: 14px;
  }
}
@media screen and (max-width: 494px) {
  .no-access__text {
    font-size: 12px;
  }

  .no-access__link {
    font-size: 12px;
  }
}
