.slide-table {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
}

.slide-table__image-container {
  position: relative;
  width: 90%;
  height: 300px;
  overflow: hidden;
}

.slide-table__img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}

.slide-table__block-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px auto 20px;
  gap: 10px;
}

.slide-table__title {
  width: 100%;
  font-size: 0.8em;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 5px 0;
  text-shadow: 4px 4px 6px rgba(6, 35, 73, 0.5);
}

.slide-table__name {
  width: 100%;
  font-size: 0.7em;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 5px 0;
}

.slide-table__wrapper {
  width: 90%;
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
  padding-bottom: 40px;
}

.slide-table__slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.slide-table__slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-table__button {
  width: 50%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.6em;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #5e5d5b;
  margin-bottom: 30px;
}

@media screen and (max-width: 769px) {
  .slide-table__wrapper {
    padding-bottom: 20px;
  }

  .slide-table__image-container {
    height: 200px;
  }
}

@media screen and (max-width: 641px) {
  .slide-table__wrapper {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
