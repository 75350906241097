.podarok {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7bb6e4;
}

.podarok__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.podarok__image-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.podarok__img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}

.podarok__title {
  width: 100%;
  font-size: 1.2em;
  padding: 6px 0;
  margin: 0;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.podarok__subtitle {
  width: 100%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: center;
}

.podarok__subtitle-contacts {
  width: 100%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: left;
}

.podarok__subtitle-btn {
  width: 90%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.9em;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.podarok__text {
  width: 90%;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  text-align: left;
}

.podarok__blocks-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: #378bc8;
  margin: 40px auto;
}

.podarok__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.podarok__header {
  display: grid;
  grid-template-columns: 74px 1fr 150px;
  justify-items: center;
  align-items: center;
  padding: 20px 10px;
  margin: 0;
  border-top: 1px solid #eee;
  align-self: stretch;
  cursor: pointer;
  gap: 10px;
}

.podarok__block-title {
  width: 100%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.9em;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  text-align: left;
  color: #eee;
}

.podarok__img-eye {
  width: 100%;
  object-fit: cover;
}

.podarok__img-icon {
  width: 100%;
  object-fit: cover;
}

.podarok__marketplace-block {
  display: flex;
  flex-direction: column;
}

.podarok__marketplace-shop {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
  padding: 30px 0;
}

.podarok__marketplace-feedback {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100px 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 15px 0;
}

.podarok__marketplace-screen {
  width: 90%;
  margin: 0 auto;
}

.podarok__marketplace-item {
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.podarok__marketplace-contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 10px 0 50px;
}

.podarok__contacts-btn {
  width: 80%;
  height: 65px;
  margin: 0;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #052435;
}

.podarok__btn-email {
  border-style: ridge;
  margin: 0 0 40px;
}

.podarok__contacts-btn:hover {
  color: #082c15;
}

.podarok__list-contact {
  width: 40%;
  margin: 0 auto;
  display: flex;
  padding: 0;
  justify-content: space-evenly;
  list-style-type: none;
  gap: 10px;
}

.podarok__contact-item img {
  max-width: 60px;
  max-height: 60px;
  transition: transform 0.3s ease;
}

.podarok__social-media {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0 30px;
  gap: 10px;
}

.podarok__telegram-block {
  width: 90%;
  margin: 0 auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3px;
}

.podarok__telegram-block img:hover {
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
}

.podarok__telegram-block img {
  max-width: 50px;
  max-height: 50px;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 768px) {
  .podarok__header {
    grid-template-columns: 40px 1fr 70px;
    gap: 5px;
  }
}
