.article {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.article__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}

.article__title {
  width: 100%;
  font-size: 0.8em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
}

.article__img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
  margin: 30px auto;
}

.article__text {
  width: 90%;
  margin: 0 auto;
  font-size: 0.8em;
  padding: 6px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: left;
}

.article__subtitle {
  width: 100%;
  font-size: 0.6em;
  padding: 0;
  padding-bottom: 10px;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
}
