.terms-and-conditions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.terms-and-conditions__wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 60px;
  z-index: 2;
}

.terms-and-conditions__title {
  width: 80%;
  color: #073042;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.55;
  text-align: center;
  font-weight: 900;
  margin: 30px 30px 0;
}

.terms-and-conditions__subtitle {
  width: 80%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 19px;
  margin: 5px;
  text-align: left;
}

.terms-and-conditions__text {
  width: 80%;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 10px;
  color: #073042;
}
