.store {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  background-color: #7bb6e4;
}

.store__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.store__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.store__text {
  text-align: center;
}

.store__marketplace-list {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
  padding: 30px 0;
}

.store__marketplace-item {
  width: 100%;
  max-width: 180px;
  min-width: 180px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .marketplace-list {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
  .marketplace-item {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
    height: 70px;
  }
}
