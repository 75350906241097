.main-title {
  width: 100%;
  margin: 0 auto;
}

.main-title__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0 40px;
  align-items: center;
}

.main-title__container {
  position: relative;
  width: 100%;
}

.main-title__image-container {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.main-title__container-img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}

.main-title__container-water {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.main-title__container-clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.main-title__text {
  font-family: ElMessiri-Bold, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #000;
  text-shadow: 4px 2px 4px rgba(241, 238, 238, 0.7);
}
.main-title__title {
  font-family: ElMessiri-Bold, sans-serif;
  color: #1a1717;
  text-shadow: 4px 2px 4px rgba(241, 238, 238, 0.7);
  font-size: 1.3em;
  margin: 0;
  margin-top: 30px;
}

.japan-formula {
  display: inline-block;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 1.5em;
  color: #161615;
}

.main-title__subtitle {
  font-family: ElMessiri-Bold, sans-serif;
  color: #1a1717;
  text-shadow: 4px 2px 4px rgba(241, 238, 238, 0.7);
  font-size: 1em;
  margin: 0;
  margin-top: 30px;
}

.main-title__title,
.main-title__subtitle {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease-out forwards;
}

.main-title__subtitle {
  animation-delay: 1s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.main-title__btn {
  position: absolute;
  top: 90%;
  right: 10%;
  color: #000;
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  border-color: #378bc8;
  font-family: ElMessiri-Medium, sans-serif;
  font-size: 0.5em;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 15px #ebe6e6;
  overflow: hidden;
}

.main-title__btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.main-title__btn:hover::before {
  height: 100%;
}

.main-title__btn:hover {
  font-family: ElMessiri-Bold, sans-serif;
  color: rgb(8, 8, 8);
}

.main-title__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(185, 187, 186, 0.3);
  z-index: 1;
}

@media screen and (max-width: 1160px) {
  .main-title__image-container {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .main-title__image-container {
    height: 300px;
  }

  .main-title__title {
    font-size: 1.3em;
    margin-top: 15px;
  }
  .main-title__subtitle {
    font-size: 0.8em;
    margin-top: 15px;
  }
  .main-title__btn {
    position: absolute;
    top: 80%;
    right: 5%;
    font-size: 0.4em;
    padding: 7px;
  }
}
