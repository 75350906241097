.nav-tab__list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.nav-tab__link {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  min-height: 30px;
  font-size: 15px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 8px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #7bb6e4;
  box-shadow: 0 0 10px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.nav-tab__link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.nav-tab__link:hover::before {
  height: 100%;
}

.nav-tab__link:hover {
  color: rgb(242, 247, 244);
}

.nav-tab__hidden {
  display: none;
}

@media screen and (max-width: 885px) {
  .nav-tab__list {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .non-mobile-style {
    position: fixed;
    top: 90px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000003;
    z-index: 1000;
    transition: all 0.4s linear;
    margin: 0;
    padding: 0;
  }

  .nav-tab__wrapper {
    position: fixed;
    width: 38%;
    height: 100vh;
    top: 90px;
    right: 0;
    bottom: 0;
    background-color: #ffffffe3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20%;
    padding: 0;
  }
  .nav-tab__list {
    width: 90%;
    padding: 20px 0;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    gap: 10px;
  }
  .nav-tab__link {
    padding: 5px;
    white-space: unset;
  }

  .nav-tab__close-burger {
    border: none;
    background: transparent;
  }
  .nav-tab__close-burger img {
    max-height: 18px;
  }
}
