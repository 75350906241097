.footer {
  width: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 20px 0 10px;
  /* z-index: 100; */
  border-top: 1px solid #080808;
}

.footer__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__block {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer__list {
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0;
  justify-content: space-around;
  text-transform: uppercase;
  list-style-type: none;
  padding-bottom: 15px;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.footer__item {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.footer__item-title {
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.5em;
  margin: 0;
  padding: 3px;
}

.footer__item-subtitle {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.4em;
  margin: 0;
  padding: 3px 5px;
  cursor: pointer;
}

.footer__support{
  border: #04040445 1px solid;
}

.footer__item-subtitle:hover {
  font-family: ElMessiri-Bold, sans-serif;
}

.footer__block-instagram {
  width: 10%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.footer__list-contact {
  margin: 0 auto;
  display: flex;
  padding: 0;
  justify-content: space-evenly;
  list-style-type: none;
  padding-bottom: 15px;
}

.footer__telegram img {
  max-width: 30px;
  max-height: 30px;
  transition: transform 0.3s ease;
  padding: 0;
  padding-bottom: 10px;
}

.footer__block-instagram {
  display: flex;
  flex-direction: column;
}

.footer__instagram img {
  max-width: 30px;
  max-height: 30px;
  transition: transform 0.3s ease;
}

.footer__asterisk {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.3em;
  color: #2b2a2a;
  margin: 0;
}

.footer__block-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer__title {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.5em;
  line-height: 25px;
  margin: 0;
  padding: 0;
  color: #073042;
}

.footer__copyright {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.5em;
  line-height: 25px;
  margin: 0;
  padding: 0;
  color: #073042;
}

hr {
  width: 90%;
  margin: 5px auto;
  border: none;
  height: 1px;
  background-color: #04040445;
}

@media screen and (max-width: 610px) {
  .footer {
    padding: 10px 0 2px;
  }

  .footer__block {
    gap: 10px;
  }

  .footer__list {
    padding-bottom: 5px;
  }

  .footer__item-title {
    font-size: 0.4em;
    padding: 2px;
  }

  .footer__item-subtitle {
    font-size: 0.3em;
    padding: 2px 4px;
  }

  .footer__telegram img {
    max-width: 20px;
    max-height: 20px;
  }

  .footer__block-instagram img {
    max-width: 20px;
    max-height: 20px;
    transition: transform 0.3s ease;
  }
  .footer__block-asterisk {
    font-size: 0.2em;
  }

  .footer__block-info {
    gap: 10px;
  }

  .footer__title {
    padding: 0;
    font-size: 10px;
  }

  .footer__copyright {
    font-size: 10px;
  }

  hr {
    margin: 2px auto;
    border: none;
    height: 0.5px;
  }
}
