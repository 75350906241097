.about-cookies {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.about-cookies__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.about-cookies__title {
  color: #000000;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.55;
  margin: 30px 30px 0;
}

.about-cookies__subtitle {
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 18px;
  line-height: 1.45;
  margin: 10px;
  text-align: center;
  color: #0a0a0ac9;
}

.about-cookies__question {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 18px;
  text-align: center;
  margin: 10px 30px;
}

.about-cookies__text {
  color: #000000;
  font-family: ElMessiri-Regular, sans-serif;
  color: #073042;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  text-align: left;
  margin: 10px 30px;
}

.about-cookies__button {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 8px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #92bbda;
  box-shadow: 0 0 10px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.about-cookies__button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.about-cookies__button:hover::before {
  height: 100%;
}

.about-cookies__button:hover {
  color: rgb(242, 247, 244);
}

ol {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .about-cookies__title {
    font-size: 18px;
  }

  .about-cookies__subtitle {
    font-size: 15px;
  }

  .about-cookies__question {
    font-size: 15px;
  }

  .about-cookies__text {
    font-size: 14px;
    text-align: center;
  }

  .about-cookies__button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
@media screen and (max-width: 494px) {
  .about-cookies__text {
    font-size: 12px;
  }
}
