.article-products {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #7bb6e4;
}

.article-products__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.article-products__block-text {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.article-products__title {
  width: 90%;
  font-size: 0.8em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: left;
  text-transform: uppercase;
  margin: 0 auto;
}

p {
  margin: 0;
}

.article-products__text {
  width: 90%;
  margin: 0 auto;
  font-size: 0.8em;
  padding: 6px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: left;
}

.article-products__block-container {
  width: 100%;
  margin: 0 auto;
}

.article-products__container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
}

.article-products__card-link {
  text-decoration: none;
}

.article-products__card {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: hidden;
}

.article-products__card-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.article-products__card-content {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0 0;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-products__card-title {
  width: 100%;
  font-size: 0.7em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  margin: 0;
}

.article-products__card-text {
  width: 100%;
  font-size: 0.7em;
  padding: 10px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 641px) {
  .article-products__container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
