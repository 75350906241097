.product-detail {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.product-detail__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.product-detail__block-text {
  width: 90%;
  margin: 0 auto;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-detail__title {
  width: 100%;
  font-size: 0.8em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  margin: 0;
}
.product-detail__subtitle {
  width: 100%;
  font-size: 0.7em;
  padding: 10px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.product-detail__block-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
}

.product-detail__img-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.product-detail__img-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.product-detail__img-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.product-detail__img {
  width: 100%;
  height: auto;
}

.product-detail__indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 30px;
  height: 5px;
  background-color: #ccc;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #7bb6e4;
}

.main-reviews__author {
  font-size: 1em;
  line-height: 1.35;
  font-family: ElMessiri-Bold, sans-serif;
  color: #000;
  margin: 10px auto;
}

.product-detail__info {
  width: 90%;
  margin: 0 auto;
}
.product-detail__info p {
  margin: 0 auto;
}

.product-detail__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #7bb6e4;
}

.product-detail__section-header {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-items: center;
  align-items: center;
  margin: 0;
  border: none;
  border-top: 1px solid #0e0d0d;
  align-self: stretch;
  cursor: pointer;
  gap: 10px;
  background-color: #7bb6e4;
}

.product-detail__section-title {
  width: 100%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.9em;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  text-align: left;
}

.icon-right {
  width: 100%;
  padding: 5px 10px;
  object-fit: cover;
}

.product-detail__section-content {
  width: 90%;
  margin: 0 auto;
  font-family: "ElMessiri-Regular", sans-serif;
  font-size: 0.8em;
  color: #000;
  overflow: hidden;
  text-align: left;
  border-top: 1px solid #378bc8;
  padding: 10px 0;
}

.bold-text {
  font-weight: bold;
  display: inline;
  line-height: 1.55;
}

.regular-text {
  font-weight: lighter;
  display: inline;
  line-height: 1.55;
}

.medium-text {
  padding-top: 10px;
  font-family: "ElMessiri-Medium", sans-serif;
  font-size: 0.4em;
  text-align: left;
}

.product-detail__marketplace-list {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 5px;
  list-style: none;
  padding: 10px 0;
}

.product-detail__marketplace-item {
  width: 100%;
  max-width: 70px;
  min-width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .product-detail__block-content {
    flex-direction: column;
    gap: 15px;
  }
}
