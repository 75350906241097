.about-us {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.about-us__container {
  position: relative;
  width: 100%;
}

.about-us__button-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-us__button-main {
  margin: 0;
}

.about-us__title {
  width: 100%;
  font-size: 0.9em;
  padding: 6px 0;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.about-us__subtitle {
  width: 100%;
  font-family: ElMessiri-Medium, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: center;
}

.about-us__topic {
  width: 100%;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: left;
}

.about-us__text {
  width: 100%;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: left;
}

@media screen and (max-width: 769px) {
  .about-us__wrapper {
    padding-bottom: 20px;
  }

  .about-us__image-container {
    height: 200px;
  }
}
