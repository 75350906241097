.header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 74px;
  background-color: #378bc8;
  z-index: 500;
  position: fixed;
}

.header__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.header__logo {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  cursor: pointer;
}

.header__logo img {
  width: auto;
  max-width: 74px;
  min-width: 45px;
  max-height: 74px;
  min-height: 45px;
  transition: transform 0.3s ease;
}

.header__logo::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
}

.header__logo:hover {
  transform: scale(1.1);
}

.header__logo:hover img {
  transform: rotate(-10deg);
}

.header__logo:hover::before {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.header__btn {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  height: 50px;
  font-size: 15px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 6px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 15px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.header__btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.header__btn:hover::before {
  height: 100%;
}

.header__btn:hover {
  color: rgb(242, 247, 244);
}

@media screen and (max-width: 885px) {
  .header__wrapper {
    gap: 10px;
  }
  .header__btn {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header__btn {
    width: 40%;
    max-height: 40px;
    font-size: 0.7em;
    padding: 4px;
  }
}
