/* VideoPlayer.css */
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 20px;
}

video {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}

.fallback-image {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}

.controls {
  width: 90%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  gap: 5px;
}

.mute-button,
.play-pause-button {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 0.4em;
  display: inline-block;

  padding: 6px;
  cursor: pointer;
  background-color: #7bb6e4;
  color: #fff;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  width: 30%;
  margin: 0;
}

.mute-button:hover,
.play-pause-button:hover {
  background-color: #0056b3;
}

.volume-slider {
  width: 30%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .video-container {
    padding: 20px 0;
  }
}
