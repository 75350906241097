.app {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  color: #000;
  position: relative;
  background-color: #378bc8;
}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

a {
  transition: 0.3s ease;
}
