.menu__close {
  padding: 0;
  border: none;
  color: rgb(18, 50, 61);
  border-radius: 2px;
  border-color: #378bc8;
  text-align: left;
  z-index: 2;
  background-color: #7bb6e4;
  box-shadow: 0 0 10px #424040;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.menu__close::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.menu__close:hover::before {
  height: 100%;
}

.menu__close:hover {
  color: rgb(242, 247, 244);
}

.menu__close img {
  width: 100%;
  max-height: 34px;
}

@media (hover: none) {
  .menu__close:active {
    background-color: #b6bcbd;
  }
}

@media screen and (max-width: 494px) {
  .menu__close img {
    max-height: 40px;
  }
}
