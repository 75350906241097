.not-found-page {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.not-found-page__wrapper {
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.not-found-page__title {
  font-family: ElMessiri-Bold, sans-serif;
  color: #000000;
  font-size: 140px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  padding: 30px 0 5px;
  margin: 0;
}

.not-found-page__subtitle {
  font-family: ElMessiri-Medium, sans-serif;
  color: #000000;
  font-size: 1em;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-bottom: 177px;
}

.not-found-page__button{
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 8px 18px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #92bbda;
  box-shadow: 0 0 10px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.not-found-page__button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.not-found-page__button:hover::before {
  height: 100%;
}

.not-found-page__button:hover {
  color: rgb(242, 247, 244);
}