@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/inter/Inter-Regular.woff2) format("woff2"),
    url(../fonts/inter/Inter-Regular.woff) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/inter/Inter-Medium.woff2) format("woff2"),
    url(../fonts/inter/Inter-Medium.woff) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/inter/Inter-Black.woff2) format("woff2"),
    url(../fonts/inter/Inter-Black.woff) format("woff");
}

@font-face {
  font-family: "ElMessiri-Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/ElMessiri/ElMessiri-Bold.woff2) format("woff2"),
    url(../fonts/ElMessiri/ElMessiri-Bold.woff) format("woff");
}

@font-face {
  font-family: "ElMessiri-Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/ElMessiri/ElMessiri-Medium.woff2) format("woff2"),
    url(../fonts/ElMessiri/ElMessiri-Medium.woff) format("woff");
}

@font-face {
  font-family: "ElMessiri-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/ElMessiri/ElMessiri-Regular.woff2) format("woff2"),
    url(../fonts/ElMessiri/ElMessiri-Regular.woff) format("woff");
}