.main-slides {
  width: 100%;
  margin: 0 auto;
}

.main-slides__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-slides__text {
  width: 100%;
  margin: 10px auto;
  font-family: ElMessiri-Bold, sans-serif;
  justify-content: space-between;
  align-items: center;
  display: flex;
  color: #000;
}
.main-slides__title {
  font-size: 0.8em;
  text-shadow: 4px 1px 4px #a7aacdb3;
}

.main-slides__subtitle {
  font-size: 0.7em;
  text-transform: uppercase;
  color: #faf7f7;
}

.main-slides__subtitle:hover {
  color: #181717;
  cursor: pointer;
}

.main-slides__container {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 150px;
}

.main-slides__inner-container {
  display: flex;
  height: 100%;
  animation: scroll 35s linear infinite;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;
  gap: 10px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50%));
  }
}

.main-slides__slide {
  width: calc(100% / var(--slides-count));
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.main-slides__arrow-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.main-slides__arrow {
  width: 50px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
}

.main-slides__left-arrow {
  transform: rotate(-180deg);
}

@media screen and (max-width: 768px) {
  .main-slides__container {
    height: 100px;
  }
  .main-slides__title {
    font-size: 0.6em;
  }
  .main-slides__subtitle {
    font-size: 0.4em;
  }
}
