.contact-form {
  width: 60%;
  margin: 40px auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #7bb6e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form input[type="checkbox"] {
  font-family: ElMessiri-Regular, sans-serif;
  text-transform: uppercase;
  padding: 10px 10px 5px;
  background-color: #7bb6e4;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #fff;
  font-size: 0.6em;
  color: #fff;
  cursor: text;
  outline: none;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="tel"]:focus,
.contact-form input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

.contact-form input[type="file"] {
  display: none; /* Скрываем поле для выбора файла */
}

.contact-form .file-label {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.8em;
  display: inline-block;
  padding: 10px 15px;
  background-color: #378bc8;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.contact-form .file-label:hover {
  background-color: #0056b3;
}

.contact-form .file-info {
  margin-bottom: 10px;
  font-size: 0.5em;
  color: #fff;
}

.contact-form .checkbox-container {
  width: 90%;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-form__checkbox-box {
  max-width: 30%;
  height: 30px;
}

.contact-form__checkbox-text {
  /* width: 30%; */
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.7em;
  /* text-align: left; */
}

.contact-form .error {
  font-family: ElMessiri-Bold, sans-serif;
  color: red;
  font-size: 0.4em;
  margin: 5px auto;
}

.error {
  color: red;
}

.contact-form__checkbox-error {
  width: 30%;
  font-family: ElMessiri-Bold, sans-serif;
  color: red;
  font-size: 0.5em;
  margin: 5px auto;
}

.contact-form__block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-form__block-text {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.7em;
  text-align: left;
}

.contact-form button[type="submit"] {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 0.8em;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 6px;
  display: inline-block;
  border-color: #e1e5e7;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 5px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-form button[type="submit"]::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.contact-form button[type="submit"]:hover::before {
  height: 100%;
}

.contact-form button[type="submit"]:hover {
  color: rgb(242, 247, 244);
  background-color: #b19f03;
}

.contact-form ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #202020;
}

.contact-form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #202020;
  opacity: 1;
}

.contact-form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #202020;
  opacity: 1;
}

.contact-form :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #202020;
}

.contact-form ::placeholder {
  /* Standard (Chrome, Firefox, Opera, Safari) */
  color: #202020;
}

.submission-status {
  font-family: ElMessiri-Bold, sans-serif;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: #dff0d8;
  color: #3c763d;
  font-size: 1em;
  text-align: center;
}

.submission-status.error {
  background-color: #f2dede;
  color: #a94442;
}
