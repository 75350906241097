.choose-taste {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.choose-taste__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.choose-taste__block-container {
  width: 100%;
  margin: 0 auto;
}

.choose-taste__list {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
}

.choose-taste__item {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: hidden;
}

.choose-taste__image {
  width: 90%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.choose-taste__title {
  width: 100%;
  font-size: 0.7em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

.choose-taste__product-title {
  width: 100%;
  font-size: 0.7em;
  padding: 0;
  font-family: "ElMessiri-Bold", sans-serif;
  text-align: center;
  margin: 0;
}

.choose-taste__product-content {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 20px 0 0;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.choose-taste__product-name {
  width: 100%;
  font-size: 0.7em;
  padding: 10px 0;
  font-family: "ElMessiri-Regular", sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.products__image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 30px 0;
}

.products__img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}
