.cookie-consent {
  width: auto;
  max-width: 350px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  background-color: #cccdcbfb;
  color: black;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 14px;
}

.cookie-consent__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cookie-consent__block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cookie-consent__text {
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 10px;
}

.cookie-consent__button-agree {
  width: 90%;
  padding: 7px;
  flex: 1;
  margin: 5px auto;
  text-align: center;
  color: #073042;
  font-family: ElMessiri-Regular, sans-serif;
}

.cookie-consent__button {
  width: 30%;
  padding: 5px 0;
  flex: 1;
  margin: 10px auto;
  text-align: center;
  color: #073042;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 12px;
}

@media screen and (max-width: 494px) {
  .cookie-consent {
    width: 90%;
    max-width: 250px;
    left: 5%;
    bottom: 10px;
    font-size: 12px;
  }

  .cookie-consent__text {
    font-size: 12px;
  }
}
