.choose-us {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7bb6e4;
}

.choose-us__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.choose-us__title {
  width: 100%;
  font-size: 0.9em;
  padding: 6px 0;
  font-family: ElMessiri-Bold, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.choose-us__subtitle {
  width: 90%;
  font-family: ElMessiri-Medium, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: left;
}

.choose-us__text {
  width: 100%;
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: left;
}

@media screen and (max-width: 769px) {
  .choose-us__wrapper {
    padding-bottom: 20px;
  }

}
