.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.privacy-policy__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 60px;
  z-index: 2;
}

.privacy-policy__title {
  color: #073042;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.55;
  text-align: center;
  font-weight: 900;
  margin: 30px 30px 0;
}

.privacy-policy__subtitle {
  font-family: ElMessiri-Medium, sans-serif;
  font-size: 19px;
  margin: 5px;
}

.privacy-policy__text {
  font-family: ElMessiri-Regular, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 30px;
  color: #073042;
}

.privacy-policy__button {
  font-family: ElMessiri-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 8px 18px;
  display: inline-block;
  border-color: #378bc8;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  background-color: #92bbda;
  box-shadow: 0 0 10px #ebe6e6;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.privacy-policy__button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #378bc8;
  z-index: -1;
  transition: height 0.5s ease;
}

.privacy-policy__button:hover::before {
  height: 100%;
}

.privacy-policy__button:hover {
  color: rgb(242, 247, 244);
}
