.main-taste {
  width: 100%;
  margin: 0 auto;
  background-color: #7bb6e4;
}

.main-taste__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-taste__text {
  width: 100%;
  margin: 10px auto;
  font-family: ElMessiri-Bold, sans-serif;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #000;
}
.main-taste__title {
  font-size: 0.8em;
  text-shadow: 6px 1px 4px #a7aacdb3;
}

.main-taste__instruction {
  font-size: 0.6em;
}

.main-taste__container {
  width: 90%;
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  gap: 20px;
  list-style: none;
}

.main-taste__slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.main-taste__slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-taste__button {
  width: 50%;
  font-family: ElMessiri-Bold, sans-serif;
  font-size: 0.6em;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #5e5d5b;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .main-taste__container {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  }
  .main-taste__title {
    font-size: 0.6em;
  }
  .main-taste__subtitle {
    font-size: 0.4em;
  }
}
